import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import firebase from 'gatsby-plugin-firebase';
import Layout from 'components/layout';
import SEO from 'components/seo';
import Logo from 'components/logo';
import Paper from 'components/paper';
import Title from 'components/title';
import BlogItem from '../components/blog_item';

const BlogPage = (props) => {
  React.useEffect(() => {
    firebase.analytics().logEvent('visited_blog');
  }, []);
  const data = useStaticQuery(graphql`
    query MyQuery {
      allContentfulBlog(filter: { node_locale: { eq: "en-US" } }) {
        edges {
          node {
            title
            slug
            banner {
              file {
                url
                fileName
                contentType
              }
            }
          }
        }
      }
    }
  `);
  return (
    <Layout>
      <SEO title='Blog' />
      <div
        style={{
          display        : 'flex',
          justifyContent : 'center',
          alignItems     : 'center',
          flexDirection  : 'column',
          paddingTop     : 20,
        }}>
        <Logo />
        <Paper style={{ marginTop: -30 }}>
          <Title>Blog</Title>
          {data.allContentfulBlog.edges.map((item, index) => (
            <BlogItem key={`BI${item.node.slug}${index}`} {...item.node} />
          ))}
        </Paper>
      </div>
    </Layout>
  );
};

export default BlogPage;
