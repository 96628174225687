import React from 'react';
import styled from 'styled-components';
import AniLink from 'gatsby-plugin-transition-link/AniLink';
import THEME from 'constants/theme';
import Title from './title';

export default function BlogItem({ title, banner, slug }) {
  return (
    <AniLink
      to={`/blog_details/${slug}`}
      paintDrip
      duration={1}
      hex={THEME.primary}
      style={{ textDecoration: 'none', color: 'inherent', display: 'flex' }}>
      <StyledBlogItemContainer backgroundImage={banner.file.url}>
        <Title>{title}</Title>
      </StyledBlogItemContainer>
    </AniLink>
  );
}

const StyledBlogItemContainer = styled.div`
  background-image: linear-gradient(to top, rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0.4)),
    url(${(props) => props.backgroundImage});
  background-size: cover;
  background-attachment: local;
  margin: 20px 0px 20px 0px;
  border-radius: 10px;
  min-height: 250px;
  display: flex;
  justify-content: center;
  align-items: center;
  h1 {
    font-size: 2rem;
    text-align: center;
    text-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
    width: 70%;
    color: ${THEME.accent};
    transition: border-width 0.3s ease-in-out;
    border-bottom: solid 2px ${THEME.primary};
    &:hover {
      border-bottom: solid 6px ${THEME.primary};
    }
    // @media (min-width: 600px) {
    //   font-size: 2.8rem;
    // }
    // @media (min-width: 768px) {
    //   font-size: 2.8rem;
    // }
    @media (min-width: 900px) {
      font-size: 2.8rem;
    }
    @media (min-width: 1200px) {
      font-size: 2.8rem;
    }
    @media (min-width: 1400px) {
      font-size: 2.8rem;
    }
  }
`;
